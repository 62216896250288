import dood1 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_01.png";
import dood2 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_02.png";
import dood3 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_03.png";
import dood4 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_04.png";
import dood5 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_05.png";
import dood6 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_06.png";
import dood7 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_07.png";
import dood8 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_08.png";
import dood9 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_09.png";
import dood10 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_10.png";
import dood11 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_11.png";
import dood12 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_12.png";
import dood13 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_13.png";
import dood14 from "../Assets/Doodoods/Doodoods_Antibiotic Bots_14.png";

export default [
  dood1,
  dood2,
  dood3,
  dood4,
  dood5,
  dood6,
  dood7,
  dood8,
  dood9,
  dood10,
  dood11,
  dood12,
  dood13,
  dood14,
];
