import "./Styles/App.scss";
import Nav from "./Components/Nav";
import Home from "./Components/Home";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Nav />
        <Home />
      </Router>
    </div>
  );
}

export default App;
