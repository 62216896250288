import footerLogo from "../Assets/PSL_8_Amili logo.png";
import supportLogo from "../Assets/PSL_Supported By.png";
import { FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import pdf from "./privacy.pdf";

const Footer = () => {
  return (
    <div className="footer">
      <div className="row">
        <div className="col-md-6">
          <h3>A Public Awareness Initiative by</h3>
          <a href="https://amili.asia/" target="_blank">
            <img src={footerLogo} alt="Amili logo" />
          </a>
        </div>
        <div className="col-md-6">
          <h3>Supported by</h3>
          <a href="https://www.ntu.edu.sg/medicine" target="_blank">
            <img src={supportLogo} alt="Supported By logo" className="pt-4" />
          </a>
        </div>
      </div>
      <div className="info">
        <p>
          AMILI is a precision gut microbiome company based in Singapore,
          serving the region. We house Southeast Asia’s first and only gut
          microbiome bank for clinical use and research. For more information,
          visit{" "}
          <a href="https://amili.asia/" target="_blank">
            www.amili.asia.
          </a>
        </p>
      </div>
      <div className="row privacy">
        <div className="col-md-4">
          <p>
            <a href={pdf} rel="noopener noreferrer" target="_blank">
              Privacy and Terms
            </a>
          </p>
        </div>
        <div className="col-md-4">
          <p>
            <a href="https://wa.me/6569777166" target="_blank">
              <FaWhatsapp /> +65 6977 7166
            </a>
          </p>
        </div>
        <div className="col-md-4">
          <p>
            <a href="mailto:donor@amili.asia" target="_blank">
              <FiMail /> donor@amili.asia
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
