const Donor = () => {
  return (
    <div className="six-page" id="donor">
      <h1>
        HOW TO
        <br />
        <span>DONATE</span>
      </h1>
      <p className="step-head">
        <b>4 easy steps</b>
      </p>
      <div className="steps">
        <div className="step">Apply!</div>
        <span>+</span>
        <div className="step">Answer General Questionnaire</div>
        <span>+</span>
        <div className="step">Send your Poop</div>
        <span>+</span>
        <div className="step">Answer Food Frequency Questionnaire</div>
      </div>
      <p>
        <b>
          We appreciate donors for their role in saving lives and hence, will
          reimburse them modestly.
        </b>
      </p>
    </div>
  );
};

export default Donor;
