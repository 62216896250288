import Header from "./Header";
import SecondPage from "./SecondPage";
import About from "./About";
import Review from "./Review";
import Donor from "./Donor";
import PoopDonor from "./PoopDonor";
import JoinUs from "./JoinUs";
import Footer from "./Footer";

const Home = () => {
  return (
    <div className="main">
      <Header />
      <SecondPage />
      <About />
      <Review />
      <Donor />
      <PoopDonor />
      <JoinUs />
      <Footer />
    </div>
  );
};

export default Home;
