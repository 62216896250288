import Carousel from "react-bootstrap/Carousel";
import biryaniText from "../Assets/Amili Header/Biryani - text.png";
import lemakText from "../Assets/Amili Header/Nasi Lemak-  texT.png";

const Header = () => {
  return (
    <div className="Header" id="home">
      <Carousel className="carousel" controls={false} pause={true}>
        <Carousel.Item interval={8000}>
          <div className="first-banner">
            <div className="left-section">
              <img src={biryaniText} alt="Biryani text" />
            </div>
            <div className="right-section">
              <h1>
                BE A<br /> POOP DONOR
                <br /> TODAY.
              </h1>
              <p>#PoopSavesLives</p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={8000}>
          <div className="second-banner">
            <div className="left-section">
              <img src={lemakText} alt="Lemak text" />
            </div>
            <div className="right-section">
              <h1>
                BE A<br /> POOP DONOR
                <br /> TODAY.
              </h1>
              <p>#PoopSavesLives</p>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Header;
