import { useState } from "react";
import { BsList } from "react-icons/bs";
import logo from "../Assets/PSL_1_Logo_white.png";
import poop from "../Assets/small_Poop Icon_Blue.png";
import { useLocation } from "react-router-dom";
const Nav = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const { hash = "" } = useLocation();

  const handleToggle = () => {
    setToggleNav(!toggleNav);
  };
  return (
    <nav className="Nav">
      <a className="head" href="#home">
        <img src={logo} alt="logo" />
      </a>
      <div className="toggle" onClick={handleToggle}>
        <BsList />
      </div>
      <ul className={toggleNav ? "enableNav" : "disableNav"}>
        <li>
          <a href="#about" className={hash === "#about" && "active"}>
            About
          </a>
        </li>
        <li>
          <a href="#donor" className={hash === "#donor" && "active"}>
            The Donor Journey
          </a>
        </li>
        <li>
          <a
            href="#poopdonor"
            className={`apply ${hash === "#apply" && "active"}`}
          >
            <img src={poop} alt="poop icon" /> Be a Poop donor
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
