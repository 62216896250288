import arrow from "../Assets/PSL_2_Scroll arrow.png";
import scoop from "../Assets/PSL_2_Scoop of poop.png";

const SecondPage = () => {
  return (
    <div className="sec-page" id="sec-page">
      <div className="scoop-img">
        <img src={scoop} alt="scoop icon" />
      </div>
      <p>
        Just <span>one scoop</span> of your poop can contribute to{" "}
        <span className="bold">GROUNDBREAKING RESEARCH</span> that will benefit
        the present and future generations to come.
      </p>
      <a href="#about" className="arrow-icon">
        <img src={arrow} alt="arrow icon" />
      </a>
    </div>
  );
};

export default SecondPage;
