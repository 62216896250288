import Carousel from "react-bootstrap/Carousel";
import hearFromDoctors from "../Assets/PSL_4_Hear from Doctors.png";
import openQuote from "../Assets/PSL_4_Quotation mark_Open.png";
import closeQuote from "../Assets/PSL_4_Quotation mark_Close.png";
import hearFromPatients from "../Assets/PSL_5_Hear from patients.png";
import hearFromDonors from "../Assets/PSL_5_Hear from donors.png";
import openQuote1 from "../Assets/PSL_5_Quotation mark_Open.png";
import closeQuote1 from "../Assets/PSL_5_Quotation mark_Close.png";

const reviewSlides = [
  {
    theme: "dark",
    review:
      "I was somewhat sceptical about GMT but I used it on my patient with moderate to severe ulcerative colitis with refractory C. difficile. She is now in clinical remission without requiring further biologic therapy",
    author:
      "Prof Ida Normiha Binte Hilmi, Professor and Senior Consultant in Gastroenterology, University of Malaya",
  },
  {
    theme: "light",
    review:
      "When I learned about stool donation, I found that the whole donation process was very easy, and clean. Being a stool donor is meaningful to me, as I can potentially make a difference and save someone’s life.",
    author: "– Existing stool donor, T. S.",
  },
  {
    theme: "dark",
    review:
      "New discoveries are being made every day about the gut microbiome and we are at a very exciting and critical moment to translate some of these findings into improving health outcomes and helping our patients. Many of the current studies are done in the West, and hence, there is a great need for clinical data and more biobanking of samples in Asia, in support of important clinical research work on the Asian population.",
    author:
      "– Associate Professor Sunny Wong, Lee Kong Chian School of Medicine, Nanyang Technological University",
  },
  {
    theme: "light",
    review:
      "After being diagnosed with C.diff, I had diarrhoea for more than five times a day where I continued to lose weight and was hospitalised. The GMT procedure was painless, and without side effects – after the procedure, I saw progress on the second day, and by the end of the week, my appetite had improved, and my stool no longer smelt odd.",
    author: "– GMT patient, H. Y.",
  },
  {
    theme: "dark",
    review:
      'The gut microbiome, the diverse ecosystem of bacteria and other organisms within our gut, is the new health frontier. A healthy microbiome is essential for good wellbeing, while many of the increasingly common "modern" diseases relate to a disturbed balance of bugs in our gut. Restoring the microbiome can be very effective in treating and curing some diseases. The most effective way of doing this is by transferring good gut bugs from a healthy person by faecal microbial transplantation, otherwise known as "poo transplant", to someone in need. Healthy people need to step up and make a donation - this is cheap and easy to do but can make a big difference to sick people in need.',
    author:
      "– Professor Dr Michael A Kamm, Gastroenterologist St Vincent’s Hospital, Professor at the University of Melbourne",
  },
  {
    theme: "light",
    review:
      "The possibilities of what gut microbiome modification can achieve in improving human health are hugely promising! That said, there remains a gap in research and public awareness, especially in this part of the world. I would encourage more people to learn about the impact of gut health on overall well-being, and also consider donating their microbiome to advance scientific research discovery.",
    author:
      "– A/Prof Dr Reuben Wong, Gastroenterologist gutCARE, Adjunct Associate Professor at the National University of Singapore",
  },
];

const Review = () => {
  return (
    <div className="fourth-page">
      <Carousel className="carousel" controls={false} pause={false}>
        {reviewSlides.map((currentReview, index) => {
          const { theme, review, author } = currentReview;
          return (
            <Carousel.Item key={index} interval={8000} className={`${theme}`}>
              <div className="innerDiv">
                <div className="header">
                  <img
                    src={
                      theme === "dark"
                        ? hearFromDoctors
                        : index === 3
                        ? hearFromPatients
                        : hearFromDonors
                    }
                    alt="Hear from Doctors"
                  />
                </div>
                <div className="content">
                  <div className="left-svg">
                    <img
                      src={theme === "dark" ? openQuote : openQuote1}
                      alt="quotes"
                    />
                  </div>
                  <h3>{review}</h3>
                  <p>{author}</p>
                  <div className="right-svg">
                    <img
                      src={theme === "dark" ? closeQuote : closeQuote1}
                      alt="quotes"
                    />
                  </div>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Review;
