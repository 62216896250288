import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Carousel from "react-bootstrap/Carousel";
import doodsImages from "./DoodoodsImges";

const Lightbox = ({ openLightbox, setOpebLightbox, activeIndex }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(activeIndex);
  }, [activeIndex]);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleClickOutside = (e) => {
    if (e.target.className === "lightbox") setOpebLightbox(false);
  };
  return (
    <div
      className="lightbox"
      style={{ display: openLightbox ? "grid" : "none" }}
      onClick={(e) => handleClickOutside(e)}
    >
      <div className="container">
        <div className="close" onClick={() => setOpebLightbox(false)}>
          <AiOutlineClose />
        </div>
        <div className="lightbox-slide">
          <Carousel
            className="carousel"
            activeIndex={index}
            controls={true}
            pause={false}
            onSelect={handleSelect}
          >
            {doodsImages &&
              doodsImages.map((dood, index) => (
                <Carousel.Item interval={3000} key={index}>
                  <img src={dood} />
                </Carousel.Item>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Lightbox;
