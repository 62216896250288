import { useState, useRef } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import doodsImages from "./DoodoodsImges";
import Carousel from "react-elastic-carousel";
import Lightbox from "./Lightbox";

const JoinUs = () => {
  const [openLightbox, setOpebLightbox] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);
  const totalSlide = Math.ceil(doodsImages && doodsImages.length);
  let resetTimeout;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 4 },
    { width: 1150, itemsToShow: 4 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];
  return (
    <div className="eight-page">
      <Lightbox
        openLightbox={openLightbox}
        setOpebLightbox={setOpebLightbox}
        activeIndex={activeIndex}
      />
      <p>
        Join us to create awareness that <span>poop can save lives!</span>
        <br />
        Share the cause with your family and friends!
      </p>
      <h1>#PoopSavesLives</h1>
      <Carousel
        ref={carouselRef}
        className="boxes"
        infiniteLoop
        onNextEnd={({ index }) => {
          const data =
            carouselRef.current &&
            carouselRef.current.getDerivedPropsFromBreakPoint();
          clearTimeout(resetTimeout);
          if (index === totalSlide - (data.itemsToShow || 4)) {
            resetTimeout = setTimeout(() => {
              carouselRef.current && carouselRef.current.goTo(0);
            }, 1500); // same time
          }
        }}
        itemsToShow={4}
        enableAutoPlay
        autoPlaySpeed={5000}
        pagination={false}
        breakPoints={breakPoints}
      >
        {doodsImages &&
          doodsImages.map((dood, index) => (
            <div
              key={index}
              onClick={() => {
                setActiveIndex(index);
                setOpebLightbox(true);
              }}
            >
              <div className="dood">
                <img src={dood} alt="poop img" />
              </div>
            </div>
          ))}
      </Carousel>
      <ul className="social-links">
        <li>
          <a href="https://facebook.com/asianmicrobiomelibrary" target="_blank">
            <FaFacebookF />
          </a>
        </li>
        <li>
          <a href="https://instagram.com/amili.asia" target="_blank">
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/amili-pte-ltd/"
            target="_blank"
          >
            <FaLinkedinIn />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCm6hA7xywQl8YZ13vGlQzTg"
            target="_blank"
          >
            <FaYoutube />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default JoinUs;
