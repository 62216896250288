import banner from "../Assets/Why_Girl.jpg";
import poop from "../Assets/PSL_3_Poop.png";

const About = () => {
  return (
    <div className="row third-page g-0" id="about">
      <div className="col-md-4 col-sm-12 left-image">
        <h1>WHY</h1>
        <img src={banner} alt="why-girl" />
      </div>
      <div className="col-md-8 col-sm-12 right-content">
        <div className="row p-0">
          <div className="col-md-2 left-text poopimg">
            <img src={poop} alt="poop" />
          </div>
          <div className="col-md-10 right-text">
            Your gut is home to trillions of micro-organisms that help us
            function in several important ways.
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 left-text">POOP TO SAVE LIVES</div>
          <div className="col-md-10 right-text">
            <p>
              The Gut Microbiome Transplant (GMT) preparations derived from poop
              donations can save the life of patients suffering from
              Clostridioides difficile <i>(C. diff)</i> infections. At present, the global
              average success rate of finding suitable GMT donors is very low
              (~2%).
            </p>
            <p>
              Your poop also contributes to groundbreaking research to treat 50 
              other serious conditions, including Inflammatory Bowel Disease 
              (IBD), Irritable Bowel Syndrome (IBS), autism, Parkinson’s Disease and many others.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 left-text">POOP FOR SCIENCE</div>
          <div className="col-md-10 right-text">
            Your poop helps build an Asia-specific gut microbiome database to
            ensure innovations and therapies developed will be effective for
            people living across Asia.
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
